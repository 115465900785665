.homecontainer {
  display: flex;
  flex-direction: column;

  // Carousel
  .carouselcontainer {
    background: rgb(59, 112, 179);
    background: -moz-linear-gradient(
      162deg,
      rgba(59, 112, 179, 1) 0%,
      rgba(40, 55, 114, 1) 100%
    );
    background: -webkit-linear-gradient(
      162deg,
      rgba(59, 112, 179, 1) 0%,
      rgba(40, 55, 114, 1) 100%
    );
    background: linear-gradient(
      162deg,
      rgba(59, 112, 179, 1) 0%,
      rgba(40, 55, 114, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b70b3",endColorstr="#283772",GradientType=1);

    height: fit-content;
    padding: 2.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .carousel {
      width: 80vw;
      margin: auto;

      .carouseinner {
        margin: auto;
        max-height: 10rem;
      }
    }
  }

  // Botones
  .buttongroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-evenly;
    width: 90vw;
    .buttonsimple {
      background-color: #f8f8f8;
      border-radius: 10px;
      color: #3b70b3;
      font-weight: 600;
      width: 200px;
      margin: 15px;
      padding: 0.5rem 1rem;
      text-align: center;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.34);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
    }
    .buttonicono {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      background-color: #f8f8f8;
      color: #707070;
      font-weight: 400;
      font-size: 12px;

      text-align: center;

      margin: 15px;
      padding: 0.5rem 1rem;
      width: 200px;
      height: 85px;
      border-radius: 10px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.34);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
      .buttoniconotext {
        margin: 0 0 0 10px;
        text-align: left;
      }
    }
  }

  // Marcas
  .marcas {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    background-color: #f0f0f0;
    margin-bottom: 50px;

    .titulomarcas {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: -25px;
      .linea {
        min-height: 5px;
        max-height: 5px;
        min-width: 100px;
        background-color: #707070;
      }
      .texto {
        font-size: 25pt;
        font-weight: 700;
        color: #3b70b3;
        margin: 0 15px 0;
        letter-spacing: 3pt;
      }
    }

    .contenedormarcas {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      padding: 0.5rem 0 1.5rem;

      .contenedorlogo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        width: 120px;
        height: 120px;
        background: #f8f8f8;
        border-radius: 10px;
        color: #707070;
        -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.05);
        -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
        .logo{
          width: 100%;
          height: auto;
        }
      }
    }
  }

  // Beneficios
  .beneficios {
    padding: 3rem 0;
    background-color: #f0f0f0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10rem 0 10rem 0;

    .card {
      width: 200px;
      height: 275px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      background-color: white;
      border-radius: 15px;
      padding-top: 1rem;
      margin: 10px 35px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);

      .icono {
        margin: 20px;
      }
      .beneficiotitulo {
        background-color: #707070;
        width: 100%;
        color: white;
        font-weight: 600;
        font-size: 18px;
      }
      .beneficiotexto {
        font-size: 10pt;
        color: #707070;
      }
    }
  }

  // Sobre nosotros
  .sobrenosotros {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 7rem 0 0 0 ; 
    background-color: #f0f0f0;
    height: 250px;
    flex-wrap: wrap;

    @media (max-width:1040px){
        margin:11rem 0;
        
        height: 30rem;
    }

    .sobreimagen {
      border-radius: 15px;
      width: 600px;
      height: auto;
      margin: -95px 0 ;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
      @media (max-width:1040px){
        margin-top:-6rem;
       
    }
    }
    .sobretexto {
      background-color: white;
      padding: 2rem;
      border-radius: 15px;
      margin-bottom: 6rem;
      margin-left: -3rem;
      margin-top:-3rem;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
        width: 500px;
        @media (max-width:1040px){
            margin-top:-5rem;
            height: fit-content;
            margin-bottom: 2rem;
        }
      .sobretitulo {
        font-size: 22pt;
        font-weight: 700;
        color: #3b70b3;
        line-height: 35px;
        letter-spacing: 3pt;
        
      }
      .descripcion{
          color: #818181;
          margin-top: 15px;
          font-size: 10pt;
          line-height: 17pt;
      }
    }
  }

  //contacto

  .contacto{
      background-color: #b9b6b6;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      height: 500px;
      margin-top: 30px;
      text-align: center;
      .whitelink{
        color: white
      }
      .form{

          background-color: white;
          width: 300px;
          padding: 1.5rem 0.5rem;
          border-radius: 10px;
          height: fit-content;
          margin: -90px 0;
          -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);

      .imgcont{
        width: 100%;
        height: auto;
    }
          .formtitulo {
            font-size: 22pt;
            font-weight: 700;
            color: #3b70b3;
            line-height: 35px;
            letter-spacing: 3pt;
            
          }
          .forminput{
              margin: 15px auto;
          }
      }
      .info{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin:15px 45px;
          .iteminfo{
              text-align: center;
              margin:5px;
              color: white;
              font-size: 10pt;
          }
      }

  }
}
