.checkout{
    margin: 30px;
    width: 900px;
    .text{
        color: gray;
        display: inline-block;
        font-size: 16pt;
        margin: 20px 0;
        line-height: 12pt;
        .value{
          display: inline;
          font-size: 20pt;
          color:#3b70b3;
        }
        .total{
         display: inline;
         font-size: 20pt;
         color:#74C11C;
       }
      }

      .textgreen{
        margin: 0 0 12px 0 ;
        font-size: 10pt;
        color: #74C11C;
      }
      
       
      .totalcont{
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
       .text{
         color: gray;
         display: inline-block;
         font-size: 16pt;
         margin: 5px 0;
         line-height: 12pt;
         .value{
           display: inline;
           font-size: 20pt;
           color:#3b70b3;
         }
         .total{
          display: inline;
          font-size: 20pt;
          color:#74C11C;
        }
       }
       .textgreen{
         margin: 0 0 12px 0 ;
         font-size: 10pt;
         color: #74C11C;
       }
      }
}

.form{

    background-color: white;
    width: 300px;
    padding:0;
    border-radius: 5px;
    height: fit-content;
    margin: 0 0;


    .formtitulo {
      font-size: 15pt;
      font-weight: 400;
      color: #3b70b3;
      line-height: 35px;
      
    }
    .forminput{
        margin: 15px auto;
    }
}
.formtitulo {
    font-size: 15pt;
    font-weight: 400;
    color: #3b70b3;
    line-height: 35px;
    
  }
.formenvio{

    width: 100%;

    padding: 0;
    border-radius: 5px;
    height: fit-content;
    margin: 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;


    .formtitulo {
      font-size: 15pt;
      font-weight: 400;
      color: #3b70b3;
      line-height: 35px;
      
    }
    .forminput{
        margin: 15px 10px;
    }
}

.emptycartcont{
    margin:auto;
    width: 600px;
    align-self: center;
    .emptycartimg{
      width: 600px;
      height: auto;
    }
    .mutedtext{
      font-size: 12pt;
      color: gray;
      text-align: center;
  }
  }

  .filecont{
    display: flex;
    flex-direction: column;
    margin: 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 15px;
  width: 800px;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  }

  .text{

          color:#3b70b3;
  }