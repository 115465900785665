.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #2068ef;
    height: 50px;

    .footertext{
        color: whitesmoke;
        font-size: 14px;
        margin: 0 1.5rem
    }
    .footericons{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 6rem;
        color: whitesmoke;
        font-size: 25px;
        margin: 0.2rem 1.5rem;
    }
    .navbarlink {
        margin: 0 5px;
        color: white !important;
      }
      .navbarlinkalt {
        margin: 0 5px;
        color: white !important;
      }
      .navbarlink:hover {
        color: #E38C00 !important;
      }
      .navbarsearch{
          padding: 0 1.5rem 0 0
      }
}