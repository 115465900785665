.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  background-color: whitesmoke;
      
  -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  
  width: 120%;
 padding: 1rem;
.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
 .text{
   color: gray;
   display: inline-block;
   font-size: 16pt;
   margin: 5px 0;
   line-height: 12pt;
   .value{
     display: inline;
     font-size: 20pt;
     color:#3b70b3;
   }
   .total{
    display: inline;
    font-size: 20pt;
    color:#74C11C;
  }
 }
 .textgreen{
   margin: 0 0 12px 0 ;
   font-size: 10pt;
   color: #74C11C;
 }
  
 .totalcont{
   align-self: flex-end;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
  .text{
    color: gray;
    display: inline-block;
    font-size: 16pt;
    margin: 5px 0;
    line-height: 12pt;
    .value{
      display: inline;
      font-size: 20pt;
      color:#3b70b3;
    }
    .total{
     display: inline;
     font-size: 20pt;
     color:#74C11C;
   }
  }
  .textgreen{
    margin: 0 0 12px 0 ;
    font-size: 10pt;
    color: #74C11C;
  }

 }
  .iconcontainer{
      width: 100px;
      height: 100px;
  }
.fadecontainer{
    background: red
   


}
  .footertext {
    color: whitesmoke;
    font-size: 12px;
    margin: 0 1.5rem;
  }
  .footericons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 6rem;
    color: red;
    font-size: 25px;
    margin: 0.2rem 1.5rem;
  }
}

.cart{
  cursor: pointer;
}


.emptycartcont{
  background: whitesmoke;
  width: 600px;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
  align-self: center;
  .emptycartimg{
    width: 600px;
    height: auto;
  }
  .mutedtext{
    font-size: 12pt;
    color: gray;
    text-align: center;
}
}
.textred{
  margin: 0 0 12px 0 ;
  font-size: 10pt;
  color: #E38C00;
}