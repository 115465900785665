.main{   
    height: 100vh; //Altura al 100 de la pantalla
    display: flex;
    flex-direction: column;
    justify-content: space-between; // separa los items
    .navbarcontainer{
        height: fit-content;
    }
    .contentcontainer{

        flex-grow: 1; // hace que el body ocupe todo el espacio
    }
    .footercontainer{
        height: fit-content;

    }
}

