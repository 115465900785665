.loading {
  margin: auto;
  color: #3b70b3;
}

.singleprodcont {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: white;
  .product {
    display: flex;
    flex-direction: row;

    width: 7        0%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: white;
    border-radius: 5px;
    padding: 5px;

    .imgcont {
      width: 100px;
      height:100px;
      border-radius: 10px;
      border: 1px solid whitesmoke;
     

    }
    .datalg {
      margin: 0px 20px;
      
     
      color: #282828;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      font-size: 11pt;
      flex-grow: 4;
    }
    .data {
      margin: 10px 20px;
      width: fit-content;
      
      color: #3f3f3f;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 1rem;
    }
    .texto {
      margin: 1px;
    }
    .precio {
    

      font-size: 20pt;
    }
  }

  .dataprice {
    margin:0 0 0 0!important;
    background: #dddddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    height: 100px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    .texto {
      color: #3d3d3d;
      margin: 1px;
    }
    .precio {
      margin: 1px;
      font-size: 20pt;
      color: #54bd2e;
    }
  }

  
}


.productinfo{
    display: flex;
    flex-direction: row;
    width:70%;
    align-items: center;


}

