.sidebarcontainer {
  background: #f0f0f0;
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .user {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0 0 0;
    font-size: 10pt;
    font-weight: 500;
    height: 130px;
    .usericoncont {
      width: 50px;
      height: 50px;
      font-size: 25pt;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: #3b70b3;
    }
  }
  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    color: #3b70b3;
    height: 50px;
    font-weight: 600;
    .line {
      width: 15px;
      height: 2px;
      background-color: #707070;
    }
    .text {
        color: #3b70b3;
      font-size: 14pt;
      margin: 0 0 0 5px;
    }
  }

  .active {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    color: white;
    height: 50px;
    background: rgb(59, 112, 179);
    background: -moz-linear-gradient(
      162deg,
      rgba(59, 112, 179, 1) 0%,
      rgba(40, 55, 114, 1) 100%
    );
    background: -webkit-linear-gradient(
      162deg,
      rgba(59, 112, 179, 1) 0%,
      rgba(40, 55, 114, 1) 100%
    );
    background: linear-gradient(
      162deg,
      rgba(59, 112, 179, 1) 0%,
      rgba(40, 55, 114, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b70b3",endColorstr="#283772",GradientType=1);

    .line {
      width: 45px;
      height: 2px;
      background-color: white;
    }
    .text {
        color: white;
      font-size: 14pt;
      margin: 0 0 0 5px;
    }
  }
}

.userName{
  color: #3b70b3;
  font-size: 1rem;
  margin: 15px;
  text-align: center;
}