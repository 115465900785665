.micuentacont {
  height: fit-content;
  background: #fafafa;
  margin: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  padding: 1rem;
  width: 400px;
  .imgcont{
      height: 200px;
      width: auto;
      margin: auto;
  }

  .text {
    color: gray;
    display: inline-block;
    font-size: 14pt;
    margin: 0px 5px 10px 0;
    line-height: normal;
  }
  .textmuted {
    color: rgb(138, 138, 138);
    display: inline-block;
    font-size: 12pt;
    margin: 0px 5px 10px 0;
    line-height: normal;
  }
  .textutil {
    color: gray;
    display: inline-block;
    font-size: 14pt;
    margin: 0px 5px 10px 0;
    line-height: normal;
    display: flex;
    align-items: center;

  }
  .value {
    display: inline-block;
    font-size: 14pt;
    margin: 0px 5px 10px 0;
    line-height: normal;

    color: #3b70b3;
  }
  .activa {
    display: inline-block;
    font-size: 14pt;
    margin: 20px 5px 10px 0;
    line-height: 1pt;

    color: #0a9f02;
  }
  .pending {
    display: inline-block;
    font-size: 14pt;
    margin: 20px 5px 10px 0;
    line-height: 1pt;

    color: #ee9004;
  }
  .totalcont {
    color: gray;
    display: inline-block;
    font-size: 16pt;
    margin: 20px 0;
    line-height: 1pt;

    width: 25%;
    text-align: right;
    .value {
      display: inline;
      font-size: 15pt;
      color: #3b70b3;
    }
    .total {
      display: inline;
      font-size: 15pt;
      color: rgb(33, 33, 33);
    }
  }
  .textgreen {
    margin: 5px 0 5px 0;
    font-size: 10pt;
    color: rgb(3, 59, 240);
    width: 15%;
    text-align: center;
  }
  .date {
    text-align: center;
    margin: 5px 0 5px 0;
    font-size: 10pt;
    color: rgb(85, 85, 85);
    width: 15%;
  }
}


