.myordercont{
  display: flex;
  flex-direction: column;
  width: 800px;
  height: fit-content;
  padding: 2rem;
  .orderline{
    background: whitesmoke;
    border: 1px rgb(210, 210, 210) solid;
    border-radius: 10px;
margin: 5px 0;
 align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2rem 1rem;
    cursor: pointer;
    .text{
      color: gray;
      display: inline-block;
      font-size: 16pt;
      margin: 20px 0;
      line-height: 1pt;
      width: 20%;
      .value{
        display: inline;
        font-size: 15pt;
        color:#3b70b3;
      }
    }
    .totalcont{
      color: gray;
      display: inline-block;
      font-size: 16pt;
      margin: 20px 0;
      line-height: 1pt;

      width: 25%;
      text-align: right;
      .value{
        display: inline;
        font-size: 15pt;
        color:#3b70b3;
      }
      .total{
       display: inline;
       font-size: 15pt;
       color:rgb(33, 33, 33);
     }
    }
    .textgreen{
      margin: 5px 0 5px 0 ;
      font-size: 10pt;
      color: rgb(3, 59, 240);
      width:15%;
      text-align: center;
    }
    .date{
      text-align: center;
      margin: 5px 0 5px 0 ;
      font-size: 10pt;
      color: rgb(85, 85, 85);
      width: 15%
    }
  }

}
.completedordercont{
  background: white;
  border-radius: 10px;
  text-align: center;
  padding: 1rem;

  min-width: fit-content;
  width: 1000px;
  .section {
    display: flex;
    width: 100%;

    justify-content: space-between;
    align-self: center;
    align-items: flex-start;
  }
  .formtitulo {
    font-size: 15pt;
    font-weight: 400;
    color: #3b70b3;
    line-height: 35px;
  }
  .contactinfo{
    width: 400px;
    text-align: left;
    .textinfo{
      font-size: 12pt;
    }
  }
  .checkoutcont {
    border: 1px rgb(221, 220, 220) solid;
    padding: 1rem;
    border-radius: 10px;
    background: white;
  }
  .section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-self: center;
    align-items: center;
  }
  .text {
    color: gray;
    display: inline-block;
    font-size: 16pt;
    margin: 20px 0 5px ;
    line-height: 1pt;
    .value {
      display: inline;
      font-size: 20pt;
      color: #3b70b3;
    }
    .total {
      display: inline;
      font-size: 20pt;
      color: #74c11c;
    }
  }

  .textgreen {
    margin: 5px 0;
    font-size: 10pt;
    color: #74c11c;
    text-align: left;
  }

  .totalcont {

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .text {
      color: gray;
      display: inline-block;
      font-size: 16pt;
      margin: 5px 0;
      line-height: 12pt;
      .value {
        display: inline;
        font-size: 20pt;
        color: #3b70b3;
      }
      .total {
        display: inline;
        font-size: 20pt;
        color: #74c11c;
      }
    }
    .textgreen {
      margin: 0 0 12px 0;
      font-size: 10pt;
      color: #74c11c;
    }
  }

}

.titulo {
  color: #3b70b3;
  font-weight: 600;
  font-size: 30pt;
  letter-spacing: 05pt;
  margin: 0 0 15px 0;
  display: inline-block
}

.emptycartcont{
 
  width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


  .emptycartimg{
    width: 600px;
    height: auto;
  }
  .mutedtext{
    font-size: 12pt;
    color: gray;
    text-align: center;
}
}