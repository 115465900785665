.checkout {
  min-width: 900px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 2rem 3rem;
  .checkoutcont {
    min-width: 1000px;

    border: 1px rgb(221, 220, 220) solid;
    padding: 1rem;
    border-radius: 10px;
    background: white;
  }
  .section {
    display: flex;
    width: 100%;

    justify-content: space-between;
    align-self: center;
    align-items: center;
  }
  .text {
    color: gray;
    display: inline-block;
    font-size: 16pt;
    margin: 20px 0;
    line-height: 12pt;
    .value {
      display: inline;
      font-size: 20pt;
      color: #3b70b3;
    }
    .total {
      display: inline;
      font-size: 20pt;
      color: #74c11c;
    }
  }

  .textgreen {
    margin: 0 0 12px 0;
    font-size: 10pt;
    color: #74c11c;
  }

  .totalcont {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .text {
      color: gray;
      display: inline-block;
      font-size: 16pt;
      margin: 5px 0;
      line-height: 12pt;
      .value {
        display: inline;
        font-size: 20pt;
        color: #3b70b3;
      }
      .total {
        display: inline;
        font-size: 20pt;
        color: #74c11c;
      }
    }
    .textgreen {
      margin: 0 0 12px 0;
      font-size: 10pt;
      color: #74c11c;
    }
  }
}

.form {
  background-color: white;
  width: 300px;
  padding: 0;
  border-radius: 5px;
  height: fit-content;
  margin: 0 0;

  .formtitulo {
    font-size: 15pt;
    font-weight: 400;
    color: #3b70b3;
    line-height: 35px;
  }
  .forminput {
    margin: 15px auto;
  }
}
.formtitulo {
  font-size: 15pt;
  font-weight: 400;
  color: #3b70b3;
  line-height: 35px;
}
.formenvio {
  width: 100%;

  padding: 0;
  border-radius: 5px;
  height: fit-content;
  margin: 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .formtitulo {
    font-size: 15pt;
    font-weight: 400;
    color: #3b70b3;
    line-height: 35px;
  }
  .forminput {
    margin: 15px 10px;
  }
}

.emptycartcont {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .emptycartimg {
    width: 600px;
    height: auto;
  }

}

.mutedtext {
    font-size: 12pt;
    color: gray;
    width: 100%;
    text-align: center;
display: flex;
justify-content: center;
align-items: center;

  }

  .completedordercont{
      background: white;
      border-radius: 10px;
      text-align: center;
      padding: 1rem;
    
  }