.loadingcont{
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 35px;
    .loading {
        margin: auto;
        color: #3b70b3;
    }
    .loadingtext{
        color: #3b70b3;
        font-size: 20pt;

    }
}
    
.productscontainer {
  width: 100%;
  max-width: 1300px;
  height: fit-content;
  background: #fafafa;
  margin: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.34);
  .titulo {
    color: #3b70b3;
    font-weight: 700;
    font-size: 30pt;
    letter-spacing: 05pt;
    margin: 20px 0 25px 25px;
    display: inline-block
  }
  .searchcont {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    margin: 0 25px;
    align-items: center;
  .searchbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    
  }
    .searchinput {
      width: 300px;
      margin: 10px 30px;
    }
  }
  .products {
    width: 100%;
    background: white;
    height: fit-content;
    border-radius: 15px;
  }
}

.prodtable {
  background: transparent;
  margin: 20px;
  border-radius: 15px;
  padding: 0.5rem;
}

.cart{
  cursor: pointer;
}


.callregister{
  background: white;
  size: 100%;
  border-radius: 15px;
  .titulo {
    color: #007bff;
    font-weight: 400;
    font-size: 16pt;
    margin-top: 15px;
  }
  .imgcont{
    height: fit-content;
    width: 200px;
    margin: auto;
  }
}