.register {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  


  .imgcont {
    width: 100%;
    height: available;
    background-color: red;
    background: url('../../assets/img/registerfondo.jpg'); 
    background-position: center;
    background-size: cover;
  }


.formcontainer {
    background: #f0f0f0;
    height: fit-content;
    width: 300px;
  
  
  align-self: center;
  
  
    .form {
      background-color: white;
      width: 350px;
      padding: 1.5rem 1rem;
      border-radius: 10px;
      height: fit-content;
      margin: 20px 30% ;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: 3;
      position: relative;
      .formtitulo {
        font-size: 22pt;
        font-weight: 700;
        color: #3b70b3;
        line-height: 35px;
        letter-spacing: 3pt;
        margin:15px 0;
      }
      .forminput {
  
        width: 100%;
        .input{
            width: 100%;
            margin:15px 0;
        }
      }
      .btn{
          margin:15px 0;
      }
    }
  }

}

.logincont{
    background: transparent!important;


.formcontainer {

    height: fit-content;

  
  
  align-self: center;
  
  
    .form {
      background-color: whitesmoke;
      
            -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
            -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
            border-radius: 10px;
      width: 350px;
      padding: 1.5rem 1rem 0.5rem;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: 3;
      position: relative;
      .formtitulo {
        font-size: 22pt;
        font-weight: 700;
        color: #3b70b3;
        line-height: 35px;
        letter-spacing: 3pt;
        margin:15px 0;
      }
      .forminput {
  
        width: 100%;
        .input{
            width: 100%;
            margin:15px 0;
        }
      }
      .btn{
          margin:15px 0;
      }
      .mutedtext{
          font-size: 12pt;
          color: gray;
          text-align: center;
      }
    }
  }
}
