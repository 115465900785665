
  //contacto


    .form{
align-self: center;
        background-color: white;
        width: 300px;
        padding: 1.5rem 0.5rem;
        border-radius: 10px;
        height: fit-content;
        margin:0 0;
        -webkit-box-shadow: 1px 1px 5px 0px rgba(110, 51, 51, 0.24);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .imgcont{
        width: 100%;
        height: auto;
    }
        .formtitulo {
          font-size: 22pt;
          font-weight: 700;
          color: #3b70b3;
          line-height: 35px;
          letter-spacing: 3pt;
          
        }
        .forminput{
            margin: 15px auto;
        }
    }
    .info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin:15px 45px;
        .iteminfo{
            text-align: center;
            margin:5px;
            color: white;
            font-size: 10pt;
        }
    }

