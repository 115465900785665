.navbarcontainer {

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    background-color: #2068ef;
    color: whitesmoke;
    .itemgroup {
      display: flex;
      flex-direction: row;
      .itemtop {
        display: flex;
      flex-direction: row;
      align-items: center;
        margin: 0 15px;
        font-family: "Open Sans";
        font-size: 14px;
        cursor: pointer;
        @media (max-width:850px) {
          font-size: 18px;
        }

        .desktop{
          margin-left: 5px;
          @media (max-width:850px) {
            display: none;
          }
        }
      }
      .whitelink{
        color: white
      }
    }
  }
  .logo{
    max-height: 35px;
    width: auto;
    margin: 0 0 0 1rem;
}
.iconcontainer{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  
  
  .icon{

    width: 35px;
    height: 35px;
    margin: 0 35px 0 0;
   
  }
  
  
}
  .navbarlink {
    margin: 0 5px;
    color: #2068ef !important;
  }
  .navbarlinkalt {
    margin: 0 5px;
    color: white !important;
  }
  .navbarlink:hover {
    color: #E38C00 !important;
  }
  .navbarsearch{
      padding: 0 1.5rem 0 0
  }
}

